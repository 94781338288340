import { Button } from "@components/controls/Button"
import { IconClose } from "@components/svg/Icons"
import { BackButton, useIsSSR } from "eddev/routing"
import { motion } from "framer-motion"
import { createPortal } from "react-dom"
import { tv } from "tailwind-variants"

type Props = {
  targetView?: keyof ViewProps | (keyof ViewProps)[]
}

const variants = tv({
  base: "fixed top-[100vh] left-[50vw] transform-gpu -translate-x-1/2 -translate-y-full pb-4 z-[49]",
})

export function FloatingBackButton(props: Props) {
  if (useIsSSR()) return null

  return createPortal(
    <BackButton
      mode="global"
      filter={props.targetView ? (route) => (Array.isArray(props.targetView) ? props.targetView.includes(route.view) : route.view === props.targetView) : undefined}
      render={(props) => (
        <div className={variants()}>
          <motion.div initial={{ y: 100, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: 100, opacity: 0 }} transition={{ stiffness: 100 }}>
            <Button size="lgSquare" onClick={props.onClick} variant="glass" leftIcon={<IconClose />} />
          </motion.div>
        </div>
      )}
    />,
    document.body,
  )
}
